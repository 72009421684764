/* @import url('https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&family=Raleway&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

.inputBoxContainer {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.materialTextfield {
  width: 100%;
  position: relative;
}

.meteraillabel {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  font-size: 0.4;
  top: 26px;
  transform: translateY(-50%);
  background: linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%);
  color: #a9a9a9;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  font-weight: 5;
}

.meteraillabelDemo {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  font-size: 10px;
  top: 26px;
  transform: translateY(-50%);
  background: linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%);
  color: #a9a9a9;
  padding: 0 0.3rem;
  margin: 0 0.5rem;
  transition: 0.1s ease-out;
  transform-origin: left top;
  pointer-events: none;
  font-weight: 5;
}

.materialInputBoxCont {
  background: #fafafa;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.materialInputBoxtwo {
  font-family: 'Roboto', sans-serif;
  background: #fafafa;
  width: 90%;
  font-size: 20;
  font-weight: 700;
  outline: none;
  border-radius: 8px;
  padding: 1rem 0.7rem;
  color: #505050;
  border: none;
  transition: 0.1s ease-out;
}

.materialInputBoxtwoDemo {
  font-family: 'Roboto', sans-serif;
  background: #fafafa;
  width: 90%;
  font-size: 20;
  font-weight: 700;
  outline: none;
  border-radius: 8px;
  padding: 1rem 0.7rem;
  color: #505050;
  border: none;
  transition: 0.1s ease-out;
}

.materialInputBox {
  font-family: 'Roboto', sans-serif;
  background: #fafafa;
  width: 100%;
  font-size: 20;
  font-weight: 700;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 1rem 0.7rem;
  color: #505050;
  transition: 0.1s ease-out;
}

.materialInputBoxDemo {
  font-family: 'Roboto', sans-serif;
  background: #fafafa;
  width: 100%;
  font-size: 20;
  outline: none;
  border: 1px solid #c4c4c4;
  border-radius: 8px;
  padding: 1rem 0.7rem;
  color: #aaaaaa;
  font-weight: 200;
  transition: 0.1s ease-out;
}

/* .materialInputBox:out-of-range {
  border-color: #6200ee;
} */

.materialInputBoxtwo:hover {
  border-color: #616161;
}

.materialInputBoxtwo:not(:placeholder-shown)+.meteraillabel {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.materialInputBoxtwo:placeholder-shown+.meteraillabel {
  background: #fafafa;
}

.materialInputBoxtwo:focus+.meteraillabel {
  background: linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%);
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.materialInputBoxtwo:-webkit-autofill,
.materialInputBoxtwo:-webkit-autofill:hover,
.materialInputBoxtwo:-webkit-autofill:focus,
.materialInputBoxtwo:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
  box-shadow: 0 0 0 30px #fafafa inset !important;
}

.materialInputBox:hover {
  border-color: #616161;
}

.materialInputBox:not(:placeholder-shown)+.meteraillabel {
  top: 0;
  transform: translateY(-50%) scale(0.9);
  font-size: 11px;
}

.materialInputBox:placeholder-shown+.meteraillabel {
  background: #fafafa;
}

.materialInputBox:focus+.meteraillabel {
  background: linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%);
  top: 0;
  transform: translateY(-50%) scale(0.9);
  font-size: 11px;
}

.materialInputBox:-webkit-autofill,
.materialInputBox:-webkit-autofill:hover,
.materialInputBox:-webkit-autofill:focus,
.materialInputBox:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
  box-shadow: 0 0 0 30px #fafafa inset !important;
}

.materialInputBoxDemo:not(:placeholder-shown)+.meteraillabelDemo {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.materialInputBoxDemo:placeholder-shown+.meteraillabelDemo {
  background: #fafafa;
}

.materialInputBoxDemo:focus+.meteraillabelDemo {
  background: linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%);
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.materialInputBoxDemo:-webkit-autofill,
.materialInputBoxDemo:-webkit-autofill:hover,
.materialInputBoxDemo:-webkit-autofill:focus,
.materialInputBoxDemo:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
  box-shadow: 0 0 0 30px #fafafa inset !important;
}

.materialInputBoxtwoDemo:not(:placeholder-shown)+.meteraillabelDemo {
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.materialInputBoxtwoDemo:placeholder-shown+.meteraillabelDemo {
  background: #fafafa;
}

.materialInputBoxtwoDemo:focus+.meteraillabelDemo {
  background: linear-gradient(180deg, #f0f0f0 50%, #fafafa 50%);
  top: 0;
  transform: translateY(-50%) scale(0.9);
}

.materialInputBoxtwoDemo:-webkit-autofill,
.materialInputBoxtwoDemo:-webkit-autofill:hover,
.materialInputBoxtwoDemo:-webkit-autofill:focus,
.materialInputBoxtwoDemo:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
  box-shadow: 0 0 0 30px #fafafa inset !important;
}

.handImage {
  position: absolute;
  z-index: 2;
  height: 120px;
  left: 370px;
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }

  65% {
    transform: translate(0, 15px);
  }

  to {
    transform: translate(0, -0px);
  }
}

.departmentListCont {
  height: 250px;
}

@media screen and (max-width: 1700px) {
  .departmentListCont {
    height: 150px;
  }
}