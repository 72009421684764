.WizardPopUpButton {
  animation: WizardPopUpButtonAnim 1s infinite;
}
:hover {
  animation-play-state: paused;
  -webkit-animation: 0;
  animation: 0;
}

.WizardPopUpButtonPointingArrow {
  animation: WizardPopUpButtonPointingArrowAnim 1s infinite;
}

@keyframes WizardPopUpButtonAnim {
  30% {
    transform: scale(1.1);
  }
  40%,
  60% {
    transform: rotate(-20deg) scale(1.1);
  }
  50% {
    transform: rotate(20deg) scale(1.1);
  }
  70% {
    transform: rotate(0deg) scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes WizardPopUpButtonPointingArrowAnim {
  0% {
    right: 130px;
    transform: translateX(0);
  }
  50% {
    right: 200px;
    transform: translateX(0%);
  }
  100% {
    right: 130px;
    transform: translateX(0%);
  }
}
