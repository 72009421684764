.left-contaniner-main-h {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: 40px;
}

.founder-name {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  margin-bottom: 10px;
}

.founder-of {
  color: var(--neutrals-warm-600, #666);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  line-height: 125%;
}

.information {
  color: var(--neutrals-warm-600, #666);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica' 'Neue';
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 125%;
  margin-left: 50px;
  margin-top: 10px;
}

.right-container-h {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 40px */
  margin-bottom: 20px;
  margin-top: 20px;
}

.right-container-desc {
  color: var(--neutrals-warm-700, #4d4d4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 0px;
  margin-top: 10px;
}

.pricing-button-cont {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background: var(--secondary-purple-100, #F2F7F4);
  padding: 12px;
  min-width: 350px;
}

.active-button {
  border-radius: 32px;
  background: var(--secondary-purple-800, #1A673F);
  display: flex;
  padding: 8px 16px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border: none;
  cursor: pointer;
  outline: none;
  color: var(--secondary-purple-200, #f2f7f4);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
  margin-right: 10px;
  margin-left: 10px;
}

.none-active-select-button {
  background-color: transparent;
  outline: none;
  border: none;
  color: var(--secondary-purple-800, #1A673F);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 10px;
}

.span-text-save {
  opacity: 0.96;
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.span-active {
  color: #f2f7f4;
}

.plan-container {
  display: flex;
  padding: 0px 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  border: 2px solid var(--secondary-purple-300, #C7DFCF);
  background: var(--neutrals-neutral-white, #fff);
}

.plan-name {
  color: var(--black, #2d2423);
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: 'Raleway'; */
  font-family: Open Sans;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .plan-name {
    font-size: 28px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .plan-name {
    font-size: 22px;
  }
}

.plan-amount {
  color: var(--secondary-purple-700,#207E4D);
  text-align: justify;
  font-family: 'Raleway';
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 20px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .plan-amount {
    font-size: 44px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .plan-amount {
    font-size: 34px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .plan-amount {
    font-size: 24px;
  }
}

.plan-duration {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
}

.offer-month {
  color: var(--secondary-purple-600, #616163);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 20px;
}

.striked-text {
  text-decoration: line-through;
  color: #29A364;
}

.plan-desc {
  color: var(--secondary-purple-600, #29A364);
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: 'Raleway'; */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.divider {
  border: 1px solid var(--neutrals-cool-300, #e0e2e6);
}

.list-container {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0px;
  padding-left: 0px;
}

.list-container li {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: 'Raleway'; */
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.select-button-active {
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-500, #29A364);
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 200px;
  color: var(--secondary-purple-600, #29A364);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-left: auto;
  margin-right: auto;
  background-color: transparent;
}

.select-button-inactive {
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-500, #29A364);
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 200px;
  color: var(--secondary-purple-600, #29A364);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  cursor: pointer;

}

@media screen and (min-width: 1200px) and (max-width: 1399px) {

  .select-button-active,
  .select-button-inactive {
    width: 150px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {

  .select-button-active,
  .select-button-inactive {
    width: 120px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {

  .select-button-active,
  .select-button-inactive {
    width: 100px;
  }
}

.most-popular-cont {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--secondary-purple-800, #1A673F);
  background: var(--secondary-purple-100, #F2F7F4);
  height: 36px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .most-popular-cont {
    width: 180px;
    height: 50px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .most-popular-cont {
    width: 140px;
    height: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .most-popular-cont {
    width: 110px;
    height: 60px;
  }
}

.most-popular-text {
  color: var(--secondary-purple-800, #207E4D);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.selected_plan_cont {
  display: flex;
  padding: 0px 24px 20px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border-radius: 8px;
  border: 2px solid var(--secondary-purple-300, #C7DFCF);
  background: var(--secondary-purple-200, #f2f7f4);
  box-shadow: 0px 10px 15px 0px rgba(41, 163, 100, 0.3);
}

.payment-tab-select-button-not-active {
  width: 320px;
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica' 'Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.payment-tab-select-button-active {
  width: 320px;
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29a364);
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica' 'Neue';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  border: none;
  outline: none;
  cursor: pointer;
}

.back-button {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
  margin-bottom: 30px;
}

.payMRGNText {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  margin-bottom: 10px;
}

.text-business {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%;
}

.text-qty {
  color: var(--neutrals-cool-500, #626870);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 15px */
}

.qty {
  color: var(--neutrals-cool-1000, #0c0d0f);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 15px */
  margin-left: 5px;
  margin-right: 5px;
}

.pay-button {
  width: 320px;
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29A364);
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}