h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.hover_container {
  max-width: 100px;
  margin-top: 0px;
  margin-right: 30px;
  position: absolute;
  top: 10px;
  right: 5em;
  z-index: 200;
}

@media only screen and (max-width: 380px) {
  .hover_container {
    right: 0;
    max-width: 100%;
    left: 10%;
    top: 80px;
  }
}

@media only screen and (min-width: 381px) and (max-width: 450px) {
  .hover_container {
    right: 0;
    max-width: 100%;
    left: 12%;
    top: 65px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 580px) {
  .hover_container {
    right: 0;
    max-width: 100%;
    left: 22%;
    top: 55px;
  }
}

@media screen and (min-width: 1041px) and (max-width: 1084px) {
  .hover_container {
    right: 6%;
  }
}

@media screen and (min-width: 1085px) and (max-width: 1119px) {
  .hover_container {
    right: 3%;
  }
}

@media screen and (min-width: 1120px) and (max-width: 1199px) {
  .hover_container {
    right: 4%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1229px) {
  .hover_container {
    right: 5%;
  }
}

@media screen and (min-width: 1230px) and (max-width: 1239px) {
  .hover_container {
    right: 5%;
  }
}

@media screen and (min-width: 1300px) {
  .hover_container {
    right: 1%;
  }
}

/* @media only screen and (min-width: 581px) and (max-width: 898px) {
  .hover_container {
    right: 10px;
    max-width: 100%;
    left: 0%;
    top: 10px;
  }
} */

.qbicons {
  /* max-width: 70px; */
  height: 35px;
  object-fit: contain;
  border-radius: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .qbicons {
    height: 25px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1199px) {
  .qbicons {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 25px;
  }
}

.icons {
  /* max-width: 70px; */
  height: 35px;
  object-fit: contain;
  border-radius: 17px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .icons {
    height: 25px;
    padding: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1199px) {
  .icons {
    margin-top: 5px;
    margin-bottom: 5px;
    height: 25px;
  }
}

/* @media screen and (max-width: 768px) {
  .hover_container {
    right: 8%;
    top: 0px;
    position: absolute;
  }
} */

.h_icon_container {
  max-width: 70%;
  /* margin-left: 30%; */
  display: flex;
  direction: row;
  justify-content: center;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .h_icon_container {
    direction: column;
    justify-content: center;
    max-width: 100%;
    /* margin-left: none; */
  }
}

@media screen and (min-width: 768px) and (max-width: 899px) {
  .h_icon_container {
    max-width: 70%;
    direction: row;
    justify-content: space-around;
    /* margin-left: 15%; */
  }
}

@media screen and (min-width: 900px) and (max-width: 959px) {
  .h_icon_container {
    justify-content: space-around;
    max-width: 70%;
    /* padding-left: 10%; */
  }
}

@media screen and (min-width: 960px) and (max-width: 979px) {
  .h_icon_container {
    justify-content: space-around;
    padding-left: 8%;
  }
}

@media screen and (min-width: 980px) and (max-width: 1199px) {
  .h_icon_container {
    justify-content: space-around;
    padding-left: 10%;
  }
}

@media screen and (min-width: 1200px) {
  .h_icon_container {
    justify-content: space-around;
    padding-left: 13%;
  }
}

.integration_button {
  background: transparent;
  border: none;
  cursor: pointer;
  /* margin-left: 15%; */
}

/* @media screen and (min-width: 768px) {
  .integration_button {
    margin-left: 15%;
  }
} */

.integration_icon {
  height: 40px;
  cursor: pointer;
  /* margin-left: 50px; */
}

.integration_icons {
  height: 36px;
  cursor: pointer;
  float: left;
  margin-right: 5px;
  /* margin-left: 50px; */
}

.card_container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: 100px;
  align-items: center;
  text-align-last: left;
}

.card_container_demo {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  height: 100px;
  align-items: center;
}

.houver_sub_cont {
  display: flex;
  flex-direction: column;
  background: #0c203b;
  border-radius: 14px;
  /* padding: 5px; */
  min-width: 80px;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .houver_sub_cont {
    flex-direction: row;
    min-width: 250px;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
}

@media screen and (min-width: 960px) and (max-width: 1199px) {
  /* .houver_sub_cont {
    min-width: 50px;
  } */

  .houver_sub_cont {
    flex-direction: column;
    min-width: 50px;
    max-width: 70px;
  }
}

@media screen and (min-width: 1200px) {
  .houver_sub_cont {
    min-width: 50px;
    max-width: 80px;
    /* max-width: 50px; */
  }
}

/* @media screen and (max-width: 960px) {
  .formContainer {
    width: 100%;
    display: column;
    justify-content: center;
  }
} */

/* @media screen and (min-width: 960px) and (max-width: 1019px) {
  .formContainer {
    width: 93%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1099px) {
  .formContainer {
    width: 85%;
    display: flex;
    justify-content: center;
  }
} */

/* @media screen and (min-width: 1100px) and (max-width: 1239px) {
  .formContainer {
    width: 80%;
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 1240px) {
  .formContainer {
    width: 70%;
    display: flex;
    justify-content: center;
  }
} */

.intImgCont {
  height: 150px;
  /* border: #0c203b solid 1px; */
  width: 80px;
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 55px;
}

@media screen and (max-width: 1041) {
  .intImgCont {
    margin-left: 0px;
  }
}

.rocket {
  margin-top: 16px;
  /* top: 35%; */
  animation-name: spaceboots;
  animation-duration: 0.8s;
  transform-origin: 50% 50%;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  position: absolute;
  z-index: 5;
}

@media screen and (max-width: 1700px) {
  .rocket {
    height: 190px;
    margin-top: 25px;
  }
}

.rocketCloud {
  margin-top: 280px;
  /* animation-name: spaceboots; */
  /* animation-duration: 0.8s; */
  /* transform-origin: 50% 50%; */
  /* animation-iteration-count: infinite; */
  /* animation-timing-function: linear; */
  position: absolute;
  z-index: 3;
  /* top: 470px; */
}

@media screen and (max-width: 1700px) {
  .rocketCloud {
    height: 80px;
    margin-top: 200px;
  }
}

/* .rocketCont {
  position: absolute;
} */

.monitorContainer {
  background-image: url('assets/images/monitor1.png');
  background-repeat: no-repeat;
  /* background-size: cover; */
  width: 390px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@keyframes spaceboots {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(0px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(2px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.monitor {
  height: 370px;
}

@media screen and (max-width: 1700px) {
  .monitor {
    height: 270px;
  }
}

.confettiImage {
  height: 420;
}

@media screen and (max-width: 1700px) {
  .confettiImage {
    height: 280px;
  }
}

.focusedLabel {
  color: var(--secondary-purple-800, #207e4d);
  font-family: 'Raleway';
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  min-width: 300px;
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.noneFocusedLabel {
  /* color: var(--secondary-purple-400, #a078fb); */
  color: #b1b3b7;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  max-width: 320px;
  min-width: 320px;
}

.focusedInputBox {
  height: 42px;
  outline: none;
  background-color: #fff;
  border: none;
  font-family: 'Raleway';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 22px; */
  caret-color: #207e4d;
  margin-top: 22px;
  border-left: 3px solid #207e4d;
  padding-left: 10px;
  color: var(--secondary-purple-600, #29a364);
}

.focusedInputBox::placeholder {
  /* color: #dfdfdf; */
  color: var(--secondary-purple-600, #29a364);
}

.nonFocusedInputBox {
  outline: none;
  border: none;
  color: #b1b3b7;
  font-family: 'Raleway';
  font-size: 16px;
  font-weight: 400;
}

.focusedInputStaticText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 21px;
  font-weight: 400;
  margin-left: 5px;
}

.nonFocusedInputStaticText {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 21px;
  color: #858585;
  font-weight: 300;
}

.focusedInputStaticTextRight {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  font-size: 18px;
  font-weight: 400;
}

.nonFocusedInputStaticTextRight {
  position: absolute;
  /* top: 50%;
  transform: translateY(-50%); */
  font-size: 21px;
  color: #858585;
  font-weight: 300;
  right: 0;
}

.focusedInputDiv {
  position: relative;
  margin-top: 22px;
}

.nonFocusedInputDiv {
  position: relative;
}

.selectBox {
  padding: 12px;
  outline: none;
  /* background-color: #fff;
  border: none; */
  /* color: var(--neutrals-neutral-black, #000); */
  color: var(--secondary-purple-600, #29a364);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  caret-color: #207e4d;
  margin-top: 22px;
  width: 300px;
  height: 44px;
  /* border-left: 3px solid #207E4D; */
  /* padding-left: 12px; */
  border-radius: 4px;
  border: 1px solid var(--neutrals-warm-300, #d8d8d8);
  background: var(--neutrals-neutral-white, #fff);
}

.selectBox option {
  color: var(--neutrals-neutral-black, #000);
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  margin-top: 10px;
}

.menuItemmyclass:hover {
  font-size: 50px;
}

.nonFocusedSelectBox {
  outline: none;
  background-color: #fff;
  border: none;
  font-family: 'Raleway';
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  caret-color: #112039;
  width: 300px;
  height: 20px;
}

.selectBox::placeholder {
  color: #dfdfdf;
}

option:first {
  color: #999;
}

/* .formNextButton {
  background-color: #d8eafa;
  color: #001b75;
  font-family: 'Montserrat';
  font-size: 14px;
  font-weight: 800;
  margin-top: 30px;
  width: 114px;
  height: 42px;
  border-radius: 8px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.formNextButton {
  background: var(--secondary-purple-600, #29a364);
  color: var(--neutrals-neutral-white, #fff);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 800;
  margin-top: 30px;
  width: 320px;
  height: 44px;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  /* bottom: 5%; */
}

.disabledFormNextButton {
  background: var(--neutrals-warm-200, #e9e9e9);
  color: gray;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 800;
  margin-top: 30px;
  width: 320px;
  height: 44px;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.formNextButtoninRevenue {
  background: var(--secondary-purple-600, #29a364);
  color: var(--neutrals-neutral-white, #fff);
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 800;
  margin-top: 30px;
  width: 320px;
  height: 44px;
  border-radius: 4px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 27%;
}

/* .formNextButton:hover {
  color: #fff;
  background-color: #00213c;
} */

.downArrowIcon {
  height: 20px;
  width: 50px;
  cursor: pointer;
}

.classifiedBox {
  display: flex;
  align-items: center;
  width: 220px;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  height: 65px;
  padding: 9px 5px 9px 10px;
  margin-right: 10px;
}

.nonFocusedClassifiedBox {
  display: flex;
  align-items: center;
  width: 160px;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  height: 40px;
  /* padding: 9px 5px 9px 10px; */
  margin-right: 10px;
}

.classifiedSelect {
  outline: none;
  background-color: #fff;
  border: none;
  font-family: 'Raleway';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 22px;
  caret-color: #112039;
  width: 170px;
  height: 50px;
}

.nonFocusedClassifiedSelect {
  outline: none;
  background-color: #fff;
  border: none;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  caret-color: #112039;
  width: 120px;
  height: 30px;
}

.locationIcon {
  font-size: x-large;
  margin-right: 5px;
  margin-top: 5px;
}

.nonFocusedLocationIcon {
  font-size: large;
  margin-right: 5px;
  margin-top: 5px;
  margin-left: 5px;
}

.box {
  display: flex;
  flex-direction: column;
  /* margin-top: 20px;
  margin-bottom: 60px; */
  min-width: 400px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.nonFocusedBox {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 60px;
}

.nonFocusedBoxTop {
  display: flex;
  flex-direction: column;
  /* margin-top: 20px;
  margin-bottom: 60px; */
  position: absolute;
  top: 25%;
  transform: translateY(-50%);
}

.nonFocusedBoxBottom {
  display: flex;
  flex-direction: column;
  /* margin-top: 20px;
  margin-bottom: 60px; */
  position: absolute;
  bottom: 20%;
  transform: translateY(50%);
}

.tabHeadings {
  color: var(--light-basic-primary, #242731);
  font-family: 'Raleway';
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 20px;
}

/* 
.tabDescription {
  color: var(--light-basic-secondary, #575f6e);
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
} */
.tabDescription {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
}

#industry-name {
  background: white;
}

.coaAccountInput {
  width: '250px';
}

.revenueBusinessTypeButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 13px;
  border-radius: 4px;
  border: 1px solid #000;
  background: #fff;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Raleway';
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-right: 10px;
  height: 45px;
  cursor: pointer;
  width: 200px;
}

.cardStyles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}

.activeCardheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #091a36;
  width: 500px;
  height: 66px;
  border-radius: 8px;
  background-color: #5fabe9;
}

.notActiveCardHeader {
  width: 500px;
  height: 36px;
  background-color: #b8d9f4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
}

.activeCardTitle {
  font-weight: 600;
  font-family: 'Montserrat';
  font-size: 17px;
  margin-right: 10px;
  margin-left: 10px;
  color: #00213c;
}

.leftContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: -90px;
}

.stepText {
  color: var(--secondary-purple-800, #207e4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 15px;
}

.tabName1 {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  margin-bottom: 20px;
}

.tabRightHeading {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  margin-bottom: 20px;
}

.tabRightDesc {
  color: var(--neutrals-cool-800, #23282e);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
}

.information {
  color: var(--neutrals-warm-600, #666);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica' 'Neue';
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 125%;
  margin-left: 50px;
  margin-top: 10px;
}

.mrginLeftsideLogo {
  width: '179px';
  height: '64px';
  margin-bottom: 90px;
}

.okButton {
  border: none;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 34px;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 17.5px */
}

.okButtonDisabled {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 34px;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.okButtonEnabled {
  cursor: pointer;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 34px;
  border-radius: 4px;
  background: #29a364;
  color: #ffffff;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
}

.okButtonContainer {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
}

.textPress {
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
}

.textEnter {
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
}

.dialogueTitle {
  color: var(--neutrals-cool-1000, #0c0d0f);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin-left: 10px;
  margin-top: 5px;
}

.textProductOnboarding {
  color: var(--neutral-cool-400, #74747c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  position: relative;
  left: 0px;
  top: 10px;
}

.tabName {
  color: var(--neutral-cool-900, #09090f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
}

.companyTabSelectionContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.selectionCard {
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-800, #207e4d);
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  /* width: 890px; */
  width: 749px;
  /* height: 151px; */
  margin-bottom: 15px;
}

.selectionCard:hover {
  background-color: var(--secondary-purple-800, #207e4d);
  color: var(--neutrals-neutral-white, #fff) !important;
}

.selectionCard:hover p {
  color: var(--neutrals-neutral-white, #fff) !important;
}

.selectionCard:hover h1 {
  color: var(--neutrals-neutral-white, #fff) !important;
}

.stepText {
  color: var(--secondary-purple-800, #207e4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 17.5px */
}

.tabHeading {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 30px */
}

.tabDesc {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.cardContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  margin-top: -5px;
}

.cardContainer h1 {
  color: var(--neutrals-cool-500, #626870);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 25px */
  text-align: left;
}

.cardContainer p {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  margin-top: -5px;
  text-align: left;
}

.cardContainer p:first-child {
  color: #207e4d;
  font-weight: 600;
}

.cardTextOption {
  color: var(--secondary-purple-800, #207e4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.selected {
  display: flex;
  height: 26px;
  padding: 2px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 20px;
  border: 1px solid var(--secondary-purple-100, #f2f7f4);
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
}

.selectedCard {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutrals-neutral-white, #fff);
  background: var(--secondary-purple-600, #29a364);
  margin-bottom: 15px;
  width: 749px;
}

.selectedCard p {
  color: var(--neutrals-neutral-white, #fff) !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.selectedCard h1 {
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 25px */
}

.continueButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29a364);
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  margin-right: 150px;
}

.continueButtonDisabled {
  display: flex;
  gap: 8px;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  margin-right: 150px;
  cursor: default;
}

.continueButtonDisabled:hover {
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
}

.inputLabel {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
  margin-bottom: 10px;
}

.inputField {
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-600, #3b424c);
  display: flex;
  padding: 12px 12px 12px 12px;
  align-items: center;
  align-self: stretch;
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  outline: none;
  width: 100%;

  /* P1 */
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  margin-right: 10px;
  margin-top: 10px;
  outline: 'none';
}

.inputPercentage {
  color: var(--neutrals-cool-1000, #0c0d0f);
  /* font-family: Helvetica Neue; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.wizardStepperText {
  color: #222;
  font-family: 'Raleway';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
  margin-top: 20px;
}

.stepName {
  color: var(--secondary-purple-900, #1a673f);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}

.stepDesc {
  color: var(--neutrals-cool-500, #626870);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.expensesCard {
  display: flex;
  width: 237px;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-800, #207e4d);
  margin: 0px 16px 16px 0px;
}

.teamCard {
  width: 237px;
  min-height: 92px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  border: 1px solid var(--secondary-purple-800, #207e4d);
  border-radius: 4px;
  gap: 13px;
}

.cardName {
  color: var(--secondary-purple-800, #207e4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 15px */
}

.cardAccount {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.cardAccountName {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
  margin-top: -2px;
}

.cardNested {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.nestedNumber {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 17.5px */
  margin-top: -10px;
}

.textExpandAll {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
}

.textCollapseAll {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
  margin-right: 18px;
}

.uploadFileCont {
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px dashed var(--secondary-purple-300, #c7dfcf);
  background: #f2f7f4;
  margin-top: 25px;
  justify-content: space-between;
  gap: 20px;
}

.drawerHeading {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 30px */
}

.drawerDesc {
  color: var(--neutrals-warm-600, #666);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.drawerHeadingSecond {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
  margin-top: 50px;
}

.drawerBoldText {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.drawerListHeading {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.drawerUploadDesc {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 15px */
}

.drawerInputLabel {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
  margin-bottom: 8px;
  outline: none;
}

.drawerInput {
  display: flex;
  padding: 12px 12px 12px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-500, #626870);
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  outline: none;

  /* P1 */
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */

  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

select.drawerInput {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.drawerAddButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;

  /* H1+/16px/h4 - 16px */
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  align-self: flex-end;
  border: none;
  margin-top: 32px;
}

.drawerSubText {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.drawerSubHeading {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.drawerSelectInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.labelStar {
  color: var(--semantic-semantic-orange-600, #e07b00);
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 100% */
}

.labelstar::before {
  text-indent: 2em;
}

.pdragAndDrop {
  color: var(--neutrals-cool-1000, #0c0d0f);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: 'Raleway';
   */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 15px */
  margin-top: 5px;
}

.spanBrowser {
  color: var(--secondary-purple-600, #29a364);
  font-feature-settings: 'clig' off, 'liga' off;
  /* font-family: 'Raleway'; */
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.spanHere {
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--neutrals-cool-500, #626870);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  padding: 0;
}

.spanHereDep {
  cursor: pointer;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  outline: none;
  text-align: center;
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  padding: 0;
}

.uploadIconImg {
  width: 38px;
  height: 33px;
}

.textFormates {
  color: var(--neutrals-cool-500, #626870);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 12.5px */
}

.textDownload {
  color: var(--neutrals-cool-500, #626870);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 10px */
}

.wizardInputBox {
  display: flex;
  padding: 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-500, #626870);
  color: var(--neutrals-cool-600, #3b424c);
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  outline: none;
  width: 100%;

  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

select.wizardInputBox {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.inputLabel {
  color: var(--neutrals-warm-1000, #131313);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.important {
  color: red;
  font-size: 22px;
}

.checkedLabel {
  color: var(--neutrals-cool-400, #898e94);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.containerInputs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 44px;
  padding: 12px 12px 12px 12px;
  gap: 8px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--neutrals-cool-500, #626870);
}

.transparentInputs {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
  border: none;
  width: '100%';
  background: transparent;
  outline: none;
  flex-grow: 1;
  box-shadow: 0 0 0 1000px white inset;
  -webkit-box-shadow: 0 0 0 1000px white inset;
}

.wizardAddButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29a364);
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  border: none;
  width: 69px;
  cursor: pointer;
  margin-top: 20px;
}

.wizardAddButtonDisabled {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  border: none;
  width: 69px;
  margin-top: 20px;
}

.wizardCancelButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-600, #29a364);
  color: var(--secondary-purple-600, #29a364);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  margin-right: 20px;
  cursor: pointer;
  background: transparent;
}

.wizardSaveButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29a364);
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  border: none;
  cursor: pointer;
  width: 69px;
}

.reviwContentHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.reviewTabSubHeading {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  /* 20px */
}

.reviewEdit {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;

  /* H1+/14px/h5 - 14px */
  /* font-family: Helvetica Neue; */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 17.5px */
}

.reviewContentTitel {
  color: var(--neutrals-cool-800, #23282e);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 15px */
}

.reviewContent {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: Raleway;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.reviewMainContents {
  display: flex;
  flex-direction: column;
}

.reviewMainContents > .reviewContentItem:nth-child(even) {
  background-color: #eaeaea;
}

.reviewExpnadHeading {
  color: var(--secondary-purple-800, #207e4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Raleway;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.reviewContentItem {
  padding: 8px;
}

.reviewContentSecField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.noDataContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.noDataContainer h1 {
  color: var(--neutrals-cool-600, #3b424c);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 30px */
}

.noDataContainer p {
  color: var(--neutrals-cool-600, #3b424c);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.noDataContainer button {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-600, #29a364);
  color: var(--secondary-purple-600, #29a364);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
  cursor: pointer;
}

.busineesTypeCardItemName {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 17.5px */
  margin-top: -1px;
}

.clientName {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.transparentAddButton {
  color: var(--secondary-purple-600, #29a364);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
}

.listContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.listContainer p {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.listCardh {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.listCardp {
  color: var(--neutrals-cool-600, #3b424c);
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.listCardItemh {
  color: var(--secondary-purple-800, #207e4d);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.completedCircle {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: var(--secondary-purple-900, #1a673f);
}

.completedCircle span {
  color: #fff;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.currentCircle {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: var(--secondary-purple-600, #29a364);
}

.currentCircle span {
  color: #fff;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.notCompletedCircle {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 6px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background: var(--secondary-purple-100, #f2f7f4);
}

.notCompletedCircle span {
  color: var(--secondary-purple-300, #c7dfcf);
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.completedText {
  color: var(--secondary-purple-900, #1a673f);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.activeText {
  color: var(--secondary-purple-600, #29a364);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.notFinishedText {
  color: var(--neutrals-cool-800, #23282e);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.stepDescription {
  color: var(--neutrals-cool-500, #626870);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-transform: none;
  white-space: nowrap;
}

.disabledButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  border: none;
}

.nestedItem {
  display: flex;
  width: 100%;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-200, #f2f7f4);
  margin-bottom: 10px;
  padding: 4px 2px;
  background-color: #f9f6ff;
}

.nestName {
  color: var(--neutrals-cool-600, #3b424c);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;

  /* P3 */
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.addButtonDisabled {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--neutrals-warm-200, #e9e9e9);
  border: 'none';
  color: var(--neutrals-warm-400, #a3a3a3);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
}

.integrationButton {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--secondary-purple-800, #207e4d);
  cursor: pointer;
  outline: none;
  margin-right: 20px;
  background: transparent;
}

.selectedIntegrationButton {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29a364);
  cursor: pointer;
  outline: none;
  margin-right: 20px;
  border: 1px solid var(--secondary-purple-800, #29a364);
}

.termsAndCond {
  color: var(--secondary-purple-600, #29a364);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-decoration-line: underline;
}

.integrationButton:hover {
  background-color: var(--secondary-purple-800, #207e4d);
  /* color: var(--neutrals-neutral-white, #fff) !important; */
}

.integrationButton img:nth-child(2) {
  display: none;
}

.integrationButton:hover img:nth-child(1) {
  display: none;
}

.integrationButton:hover img:nth-child(2) {
  display: block;
}

.selectedIntegrationButton img:nth-child(1) {
  display: none;
}

.congratulationTabH {
  color: var(--neutrals-warm-1000, #131313);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 30px */
  margin-bottom: 20px;
  margin-top: 30px;
}

.congratulationTabP {
  color: var(--neutrals-warm-600, #666);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 20px */
}

.congratsCloseButton {
  display: flex;
  height: 44px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--secondary-purple-600, #29a364);
  border: none;
  color: var(--neutrals-neutral-white, #fff);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Helvetica';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 20px */
  margin-right: 15px;
  cursor: pointer;
  outline: none;
}

.talkToAnExpertButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  border: none;
  color: var(--neutrals-cool-900, #181a1e);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
  cursor: 'pointer';
  outline: 'none';
}

.drawerPageNumber {
  color: var(--secondary-purple-400, #a078fb);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 15px */
}

.confirmPageHeadings {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  /* 25px */
}

.demoInfoText {
  color: var(--neutrals-cool-1000, #0c0d0f);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
}

.demoInfoTextp {
  color: var(--neutrals-cool-600, #3b424c);
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;

  /* P2 */
  font-family: 'Raleway';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  /* 17.5px */
}

.toogleButtonText {
  color: var(--neutrals-cool-400, #898e94);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Raleway';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  /* 20px */
}

.splInput {
  outline: none;
  background-color: #fff;
  border: none;
  font-family: 'Raleway';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  /* line-height: 22px; */
  caret-color: #207e4d;
  margin-top: 22px;
  border-left: 3px solid #207e4d;
  padding-left: 10px;
  color: var(--secondary-purple-600, #29a364);
}

.splInput::placeholder {
  color: gray;
}
